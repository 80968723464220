import React, {useContext} from "react";
import Logo from "../assets/glomo_logo.svg";
import ISOCertification from "../assets/iso_logo.png";
import SOCCertification from "../assets/soc_logo.png";
import ISO373012021Certification from "../assets/ISO373012021_logo.svg";
// 1) Add import for a LinkedIn logo (assuming you have a file):
import LinkedInLogo from "../assets/linkedin_logo.svg";
import {useNavigate} from "react-router-dom";
import {MyContext} from "./Layout";

const Footer = () => {
    const navigate = useNavigate();
    const callMethod = useContext(MyContext);

    // 2) Add a 4th item to the “COMPANY” section for LinkedIn:
    const footerLinks = [
        {
            id: 1,
            title: "COMPANY",
            links: [
                {
                    id: 1,
                    title: "About us",
                    url: "/about-us",
                },
                {
                    id: 2,
                    title: "Blog",
                    url: "https://blog.glomopay.com/",
                },
                {
                    id: 3,
                    title: "Demo",
                    isClickable: true,
                    method: callMethod,
                },
                {
                    id: 4,
                    // Here we show the LinkedIn logo + text (or logo only, if preferred)
                    title: (
                        <>
                            <img
                                src={LinkedInLogo}
                                alt="LinkedIn"
                                style={{width: "16px", marginRight: "6px"}}
                            />

                        </>
                    ),
                    url: "https://www.linkedin.com/company/glomopay",
                },
            ],
        },
        {
            id: 2,
            title: "PRODUCT",
            links: [
                {
                    id: 1,
                    title: "Checkout",
                    url: "/products/checkout",
                },
                {
                    id: 2,
                    title: "Payment links",
                    url: "/products/payment-links",
                },
                {
                    id: 3,
                    title: "Recurring Payments",
                    url: "/products/recurring-payments",
                },
                {
                    id: 4,
                    title: "Payouts",
                    url: "/products/payouts",
                },
                {
                    id: 5,
                    title: "Global Treasury",
                    url: "/products/treasury",
                },
            ],
        },
        {
            id: 3,
            title: "SOLUTIONS",
            links: [
                {
                    id: 1,
                    title: "Financial Institutions",
                    url: "/solutions/financial-institutions",
                },
                {
                    id: 2,
                    title: "Marketplaces",
                    url: "/solutions/marketplaces",
                },
                {
                    id: 3,
                    title: "Global Business",
                    url: "/solutions/global-business",
                },
            ],
        },
        {
            id: 4,
            title: "LEGAL",
            links: [
                {
                    id: 1,
                    title: "Terms & Conditions",
                    url: "/legal#terms-and-conditions",
                },
                {
                    id: 2,
                    title: "Privacy Policy",
                    url: "/legal#privacy",
                },
                {
                    id: 3,
                    title: "Merchant Onboarding",
                    url: "/legal#merchant-onboarding",
                },
            ],
        },
    ];

    return (
        <div className="footer">
            <div className="container border-bottom border-secondary pb-3">
                <div className="row gy-5" style={{marginBottom: 8}}>
                    <div className="col-lg-4 col-12">
                        <div className="d-flex flex-column">
                            <img className="logo mb-4" alt="Glomo Logo" src={Logo}/>

                            {/*
                  3) Insert the new text paragraph between the Logo and
                     certification badges. Adjust styling as needed.
              */}
                            <p style={{color: "#FFFFFF", fontSize: "0.9rem", marginBottom: "1rem"}}>
                                Glomo Payments IFSC Private Limited (“Glomo”) is an authorised
                                Payments Services Provider (“PSP”) under IFSCA (Payment Services)
                                Regulations, 2024 (“PSP Regulation”) under the Authorisation No.
                                IFSC/PSP/2024-25/001. As a regulated PSP it is authorised to
                                undertake following activities:
                                <br/>
                                1. account issuance service (including e-money account issuance service),
                                <br/>
                                2. e-money issuance service,
                                <br/>
                                3. cross border money transfer service,
                                <br/>
                                4. merchant acquisition service as payment service(s)
                                <br/>
                                as specified in Part A of Schedule I of the PSP Regulation
                            </p>

                            <div
                                style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    gap: "12px",
                                }}
                            >
                                <a
                                    href="https://trust.glomopay.com/"
                                    style={{textDecoration: "none"}}
                                >
                                    <img
                                        className="certification-badge"
                                        alt="ISO 27001 Certification"
                                        src={ISOCertification}
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                        }}
                                    />
                                </a>
                                <a
                                    href="https://trust.glomopay.com/"
                                    style={{textDecoration: "none"}}
                                >
                                    <img
                                        className="certification-badge"
                                        alt="SOC 2 Certification"
                                        src={SOCCertification}
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                        }}
                                    />
                                </a>
                                <a
                                    href="https://trust.glomopay.com/"
                                    style={{textDecoration: "none"}}
                                >
                                    <img
                                        className="certification-badge"
                                        alt="ISO 37301:2021 Certification"
                                        src={ISO373012021Certification}
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                        }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-12">
                        <div className="row g-5 g-lg-0">
                            {footerLinks.map((footerLink) => (
                                <div className="col-lg-3 col-6" key={footerLink.id}>
                                    <div className="content-section">
                                        <div className="content-category">{footerLink.title}</div>
                                        <div className="footer-links">
                                            {footerLink.links.map((link) => (
                                                <div
                                                    className="link"
                                                    key={link.id}
                                                    onClick={() => {
                                                        if (link.isClickable) {
                                                            link.method();
                                                        } else {
                                                            window.scrollTo(0, 0);
                                                            if (link.url.startsWith("http")) {
                                                                window.location.href = link.url;
                                                            } else {
                                                                navigate(link.url);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {link.title}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright">
                <div className="container">
                    <div className="font-weight-bold">© 2025 Glomopay</div>
                    <div>
                        Glomo Payments IFSC Private Limited (“Glomo IFSC”), regulated as Payment Service
                        Provider under IFSCA (Payment Services) Regulations, 2024
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;