import React from "react";
import Logo from "../assets/glomo_logo_blue.svg";
import LoginCta from "./LoginCta";
import { useNavigate } from "react-router-dom";
import ShoppingCartBlack from "../assets/ShoppingCartBlack.svg";
import Link from "../assets/LinkBlack.svg";
import RotateBlack from "../assets/RotateBlack.svg";
import HandCoins from "../assets/HandCoinsBlack.svg";
import Vault from "../assets/VaultBlack.svg";
import Union from "../assets/union-black.svg";
import GlobeIcon from "../assets/GlobeIcon-black.svg";
import Marketplace from "../assets/marketplace-black.svg";
import Blog from "../assets/Blog.svg";
import Code from "../assets/Code.svg";
import ChevronRight from "../assets/ChevronRight.svg";

const navItems = [
  {
    label: "Product",
    dropdown: [
      {
        label: "Checkout",
        icon: ShoppingCartBlack,
        url: "/products/checkout",
      },
      {
        label: "Payment links",
        icon: Link,
        url: "/products/payment-links",
      },
      {
        label: "Recurring Payments",
        icon: RotateBlack,
        url: "/products/recurring-payments",
      },
      {
        label: "Payouts",
        icon: HandCoins,
        url: "/products/payouts",
      },
      {
        label: "Treasury",
        icon: Vault,
        url: "/products/treasury",
      },
    ],
  },
  {
    label: "Solution",
    dropdown: [
      {
        label: "Financial Institutions",
        icon: Union,
        url: "/solutions/financial-institutions",
      },
      {
        label: "Marketplaces",
        icon: Marketplace,
        url: "/solutions/marketplaces",
      },
      {
        label: "Global Business",
        icon: GlobeIcon,
        url: "/solutions/global-business",
      },
    ],
  },
  // {
  //   label: "Resources",
  //   dropdown: [
  //     {
  //       label: "Blog",
  //       icon: Blog,
  //       url: "/",
  //     },
  //     {
  //       label: "Developer tools",
  //       icon: Code,
  //       url: "/",
  //     },
  //   ],
  // },
];

const Header = ({ noNav = false }) => {
  const navigate = useNavigate();

  const closeMenuOnClickOutside = (event) => {
    const menu = document.getElementById("dropdownMenu");
    const hamburger = document.querySelector(".hamburger");

    if (!menu.contains(event.target) && !hamburger.contains(event.target)) {
      menu.style.display = "none";
      document.removeEventListener("click", closeMenuOnClickOutside);
    }
  };

  const toggleMenu = () => {
    const menu = document.getElementById("dropdownMenu");
    const isVisible = menu.style.display === "block";
    menu.style.display = isVisible ? "none" : "block";

    if (!isVisible) {
      document.addEventListener("click", closeMenuOnClickOutside);
    } else {
      document.removeEventListener("click", closeMenuOnClickOutside);
    }
  };

  const toggleSubMenu = (index) => {
    if (index === undefined) {
      return;
    }
    const submenu = document.querySelector(`.submenu-${index + 1}`);
    submenu.style.display =
      submenu.style.display === "block" ? "none" : "block";
  };

  const renderLogo = () => {
    return (
      <img
        onClick={() => navigate("/")}
        className="navbar-brand"
        alt="Navbar brand"
        src={Logo}
        style={{
          width: '104.545px',
          height: '20px'
        }}
      />
    );
  };

  return (
    <>
      <div className="header">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="d-flex align-items-center"
              style={{ columnGap: 16 }}
            >
              {renderLogo()}
              <div className="navbar-items">
                {navItems.map((item, index) => (
                    <div className="navbar-link" key={index}>
                      {item.label}
                      {item.dropdown.length > 0 && (
                          <ul className="dropdown p-0">
                            <div className="px-3 py-2">
                              {item.dropdown.map((subitem, subIndex) => (
                                  <li
                                      key={subIndex}
                                      className="dropdown-item"
                                      onClick={() => navigate(subitem.url)}
                                  >
                                    <img src={subitem.icon} alt="icon"/>
                                    <div className="dropdown-item-label">
                                      {subitem.label}
                                    </div>
                                  </li>
                              ))}
                            </div>
                          </ul>
                      )}
                    </div>
                ))}

                <div
                    className="navbar-link"
                    onClick={() => {
                      window.location.href = "https://blog.glomopay.com";
                    }}
                >
                  <div className="div">Blog</div>
                </div>
                <div
                    className="navbar-link"
                    onClick={() => navigate("/about-us")}
                >
                  <div className="div">About us</div>
                </div>
              </div>
            </div>
            {!noNav && <LoginCta/>}
          </div>
        </div>
      </div>
      <header className="mobile-header">
        <img
            onClick={() => navigate("/")}
            className="logo"
          alt="Navbar brand"
          src={Logo}
        />
        <div className="hamburger" onClick={toggleMenu}>
          &#9776;
        </div>
        <div id="dropdownMenu" className="menu">
          <ul>
            {navItems.map((item, index) => (
              <li
                key={index}
                className={`${
                  (item?.dropdown || []).length !== 0 ? "has-submenu" : ""
                }`}
              >
                <div
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => toggleSubMenu(index)}
                >
                  <div className="title">{item.label}</div>
                  <img src={ChevronRight} alt="img" />
                </div>
                {(item?.dropdown || []).length !== 0 && (
                  <ul id="submenu" className={`submenu-${index + 1}`}>
                    {(item?.dropdown || []).map((subitem, subIndex) => (
                      <li
                        key={subIndex}
                        onClick={() => {
                          toggleMenu();
                          toggleSubMenu();
                          navigate(subitem.url);
                        }}
                      >
                        <div className="title">{subitem.label}</div>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}

            <li>
              <div
                className="title"
                onClick={() => {
                  toggleMenu();
                  navigate("/about-us");
                }}
              >
                About us
              </div>
            </li>
          </ul>
          <div className="d-flex justify-content-center w-100 mt-4">
            <LoginCta callback={() => toggleMenu()} />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
